<template>
    <div :class="`wrapper  wrapper--default m-3 p-2`">
        <Loading v-if="loading" />
        <table v-else :class="`w-100 table--perweek table__border--default`">
            <thead :class="`table__head--default`">
                <th class="table__head--text">ID</th>
                <th class="table__head--text">Datum</th>
                <th class="table__head--text">Aansprakelijk</th>
                <th class="table__head--text">Prospect</th>
                <th class="table__head--text">Klantnummer</th>
                <th class="table__head--text">Klantnaam</th>
                <th class="table__head--text">Land</th>
                <th class="table__head--text">Omzet</th>
                <th class="table__head--text">Order</th>
                <th class="table__head--text">Retour</th>
                <th class="table__head--text">Status</th>
                <th class="table__head--text">Prio</th>
            </thead>
            <tbody>
                <tr v-for="(item, key) in truckparts_requests" :key="key"  class="`table__row--color`">
                    <td class="table__cell--default text-center">{{ item.id }}</td>
                    <td class="table__cell--default">{{ item.datum }}</td>
                    <td class="table__cell--default">{{ item.aansprakelijk }}</td>
                    <td v-if="item.prospect == 0" class="table__cell--default"></td>
                    <td v-if="item.prospect == 1" class="table__cell--default">Yes</td>
                    <td v-if="item.klantnummer == 0" class="table__cell--default">-</td>
                    <td v-else class="table__cell--default">{{ item.klantnummer }}</td>
                    <td class="table__cell--default">{{ item.klantnaam }}</td>
                    <td class="table__cell--default">{{ item.land }}</td>
                    <td class="table__cell--default">€ {{ item.omzetverwachting }}</td>
                    <td class="table__cell--default">{{ item.ordernummer }}</td>
                    <td v-if="item.retour == 'ja'" class="table__cell--default">Yes</td>
                    <td v-else class="table__cell--default"></td>
                    <td v-if="item.status == 'gefactureerd'" class="table__cell--default bg-green">Gefactureerd</td>
                    <td v-else-if="item.status == 'lopend'" class="table__cell--default bg-blue">Lopend</td>
                    <td v-else-if="item.status == 'gecancelled'" class="table__cell--default bg-red">Gecancelled</td>
                    <td v-else-if="item.status == 'geweigerd'" class="table__cell--default bg-red">Geweigerd</td>
                    <td v-else class="table__cell--default">{{ item.status }}</td>
                    <td class="table__cell--default">{{ item.prioriteit }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
  
<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue"

export default {
    components: { Loading },
    data: () => ({
        truckparts_requests: null,
        loading: true,
    }),
    created() {
        this.getData();
    },
    methods: {
        getData() {
            request('truckparts/requests', "GET").then(({ truckparts_requests }) => {
                this.truckparts_requests = truckparts_requests
                this.loading = false;
            })
        }
    }
}
</script>
   